import React from "react";
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Dorota from "../../images/team/dorota-small.png";
import cert1 from "../../images/certyfikaty_dorota/cer_dor_1.jpg";
import cert2 from "../../images/certyfikaty_dorota/cer_dor_2.jpg";
import cert3 from "../../images/certyfikaty_dorota/cer_dor_3.jpg";
import cert4 from "../../images/certyfikaty_dorota/cer_dor_4.jpg";
import cert5 from "../../images/certyfikaty_dorota/cer_dor_5.jpg";
import cert6 from "../../images/certyfikaty_dorota/cer_dor_6.jpg";
import cert7 from "../../images/certyfikaty_dorota/cer_dor_7.jpg";
import cert8 from "../../images/certyfikaty_dorota/cer_dor_8.jpg";
import cert9 from "../../images/certyfikaty_dorota/cer_dor_9.jpg";

const certification = [
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  cert6,
  cert7,
  cert8,
  cert9
];

const handleClick = () => {
  window.medicall_modal('5cc885177f8c268d5f62');
  return false;
};

const DorotaMaliszewska = (props) => {
  return (
    <Layout {...props}>
			<TeamPerson img={Dorota} handleClick={handleClick} certification={certification} person="dorota" />
		</Layout>
  );
};
export default DorotaMaliszewska;
